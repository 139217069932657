import { Controller } from "@hotwired/stimulus"
import TomSelect from 'tom-select';

export default class extends Controller {
  static targets = ["regions", "areas"]

  connect() {
    new TomSelect(this.regionsTarget, {
      plugins: {
        remove_button: {
          title:'Remove this item',
        }
      }
    })
    new TomSelect(this.areasTarget, {
      plugins: {
        remove_button: {
          title:'Remove this item',
        }
      }
    })
  }
}
