import { Controller } from "@hotwired/stimulus"
import noUiSlider from 'nouislider'

export default class extends Controller {
  static targets = ["slider", "min", "max", "minput", "maxput"]

  static values = {
    config: Object
  }

  connect() {
    this.slider = noUiSlider.create(this.sliderTarget, this.configValue);

    let controller = this

    this.slider.on('update', (values) => {
      if (controller.configValue.money) {
        controller.minTarget.textContent = controller.formatMoney(values[0])
        controller.maxTarget.textContent = controller.formatMoney(values[1])
      }

      if (controller.hasMinputTarget) {
        controller.minputTarget.value = Number(values[0])
      }

      if (controller.hasMaxputTarget) {
        controller.maxputTarget.value = Number(values[1])
      }
    })
  }

  formatMoney(number) {
    number = Number(number)
    let output = "£"

    if (number >= 1000000) {
      output += (number / 1000000) + "m"
    } else {
      output += (number / 1000) + "k"
    }

    return output
  }
}
