import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "template", "container" ]

  connect() {
    this.updateCounts()
    this.updateRemoves()
  }

  add(event) {
    event.preventDefault()

    let new_item = this.templateTarget.content.firstElementChild.cloneNode(true)
    new_item.innerHTML = new_item.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())

    this.containerTarget.appendChild(new_item)

    let inputs = new_item.querySelectorAll('input[type="text"]')
    inputs[inputs.length - 1].focus()

    this.updateCounts()
    this.updateRemoves()
  }

  remove(event) {
    event.preventDefault()

    if (!confirm("Are you sure?")) { return }

    let wrapper = event.target.closest(".nested-fields")

    // New records are simply removed from the page
    if ("new" in wrapper.dataset) {
      wrapper.remove()

    // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'
    }

    this.updateCounts()
    this.updateRemoves()
  }

  updateCounts() {
    let count = 1
    let counts = this.element.querySelectorAll('[data-count]')

    counts.forEach((item) => {
      item.innerHTML = count
      count += 1
    })
  }

  updateRemoves() {
    if (!this.data.has("minItems")) { return }

    let removes = this.element.querySelectorAll('[data-remove]')
    const num_items = removes.length
    const min_items = parseInt(this.data.get("minItems"))

    if (num_items <= min_items) {
      // hide remove links
      this.element.querySelector('[data-remove]').classList.add('hide')
    } else {
      // show remove links
      removes.forEach((item) => {
        item.classList.remove('hide')
      })
    }
  }
}
