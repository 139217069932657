import { Controller } from "@hotwired/stimulus"

import debounce from "lodash.debounce"

export default class extends Controller {
  static targets = [ 'textarea' ]

  initialize() {
    // debounce init for resize events
    this.init = debounce(this.init, 250)

    // set initial height and style
    this.init()

    this.textareaTargets.forEach((textarea) => {
      textarea.style.overflowY = 'hidden';
    });
  }

  update(e) {
    this.resize(e.target);
  }

  resize(textarea) {
    let disabled = textarea.disabled
    let initial_height = textarea.offsetHeight

    textarea.disabled = false
    textarea.style.height = 'auto';

    if (textarea.scrollHeight > 0) { textarea.style.height = textarea.scrollHeight + 'px'; }

    textarea.disabled = disabled

    if (initial_height != textarea.scrollHeight) {
      let event = new CustomEvent('autosize:resized', { bubbles: true })
      textarea.dispatchEvent(event)
    }
  }

  init() {
    console.log("init!")
    this.textareaTargets.forEach((textarea) => {
      this.resize(textarea);
    });
  }
}
