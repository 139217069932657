import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []

  reset(e) {
    console.log('rseting', e)
    let frame = e.target
    const y = frame.getBoundingClientRect().top + window.pageYOffset - 120
    console.log(frame.getBoundingClientRect().top, window.pageYOffset)
    window.scrollTo({top: y, behaviour: "smooth"})
  }
}
