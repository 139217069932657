import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.focus()

    let value = this.element.value

    this.element.value = ''
    this.element.value = value
  }
}
