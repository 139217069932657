import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { faHeart as fasHeart, faExternalLinkAlt as fasExternalLinkAlt, faTimesCircle, faCheckCircle, faUpload, faArrowLeft, faArrowRight, faCog, faDownload } from '@fortawesome/free-solid-svg-icons'
import { faHeart as farHeart, faArrowAltCircleRight as farArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons'

library.add(faCog, faDownload, fasHeart, farHeart, fasExternalLinkAlt, farArrowAltCircleRight, faTimesCircle, faCheckCircle, faUpload, faArrowLeft, faArrowRight)


// Replace any existing <i> tags with <svg> and set up a MutationObserver to
// continue doing this as the DOM changes.
dom.watch()
